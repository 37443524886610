<template>
  <div class="address">
    <ShopNavBar title="收货地址" />

    <div class="address-list">
      <div class="address-item" v-for="item in list" :key="item.id">
        <div class="address-info">
          <div>
            {{ item.username }} {{ item.usertel }}
            <van-tag color="#ff2d2e" style="margin-left:12rpx" v-if="item.isdefault == 1">默认</van-tag>
          </div>
          <div class="address-details">{{ item.sheng + item.shi + item.xian + item.address }}</div>
        </div>
        <div class="address-update">
          <q-icon name="edit" @click="tolink('addressUpdate?id=' + item.id)"></q-icon>
        </div>
      </div>
    </div>

    <van-empty description="暂无收货地址" v-if="list.length == 0" />

    <div class="btn" @click="tolink('addressAdd')">添加地址</div>
  </div>
</template>

<script>
import { NavBar, Empty, Tag } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [NavBar.name]: NavBar,
    [Empty.name]: Empty,
    [Tag.name]: Tag,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    tolink(path) {
      this.$router.push(path)
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "Api/UsersAddress/List",
        {
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.list = data
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.address {
  min-height: 100vh;
  box-sizing: border-box;
  background: rgba(242, 242, 242, 1);
}

.address :deep(.van-button--danger) {
  background-color: rgba(255, 45, 46, 1);
}

.address-list {
  padding: 10px;
}

.address-item {
  margin-bottom: 10px;
  padding: 15px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.address-details {
  margin-top: 4px;
  font-size: 13px;
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.address-info {
  flex: 1;
}

.address-other {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(187, 187, 187, 0.4);
}

.address-row {
  display: flex;
  align-items: center;
}

.address-update {
  padding: 0 10px;
}

.btn {
  margin: 10px 10px 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 46px;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}
</style>